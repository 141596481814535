import { City } from '@/typings/CommonTypings'

export interface Dealership {
  id?: number;
  name: string;
  logo: string;
  location: DealershipLocation;
  status: DealershipStatus;
  network: DealershipNetwork;
}

export interface DealershipLocation {
  id?: number;
  address: string;
  city: City;
  lon: number;
  lat: number;
}

export interface DealershipStatus {
  id: number;
  name: string;
}

export interface DealershipNetwork {
  id: number;
  name: string;
}

export enum DealershipStatusId {
  Active = 1,
  Locked = 2,
  Moderation
}
