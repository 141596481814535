
import Vue from 'vue'
import Component from 'vue-class-component'
// import Axios from 'axios'
import {
  CarBrand,
  City,
  Networks,
  PartnerCategory,
  PartnerDetails,
  Region,
  Status
} from '@/typings/AdminTyping'
import { Prop, Watch } from 'vue-property-decorator'
import Partners from '@/apis/Partners'

@Component({
  name: 'AdminPartnerForm'
})

export default class AdminPartnerForm extends Vue {
  @Prop(Array) networks!: Networks[]
  @Prop(Array) brands!: CarBrand[]
  @Prop(Array) cities!: City[]
  @Prop(Array) regions!: Region[]
  @Prop(Array) statuses!: Status[]
  @Prop(Object) partnerDetails!: PartnerDetails
  @Prop(Boolean) isNotNewPartner!: boolean

  categories = [
    { id: 1, name: 'Автосалон' },
    { id: 2, name: 'Лизинговая компания' },
    { id: 3, name: 'Сеть автосалонов' }
  ]

  subjects = [
    { id: 1, name: 'Легковой' },
    { id: 2, name: 'Грузовой' },
    { id: 3, name: 'Б/У' },
    { id: 4, name: 'Коммерческие' },
    { id: 5, name: 'Спецтехника' },
    { id: 6, name: 'Оборудование' }
  ]

  transactionTypes = [
    { id: 1, name: 'Юр. лица' },
    { id: 2, name: 'Физ. лица' }
  ]

  categoryId = 0
  partnerId = 0

  searchInput = ''
  searchCityInput = ''
  brandMatches = this.brands
  citiesMatches = this.cities

  @Watch('searchInput')
  onSearchInputChange(v: string) {
      this.querySelections(v)
  }

  @Watch('searchCityInput')
  onSearchCityInputChange(v: string) {
      this.queryCitySelections(v)
  }

  querySelections(v: string) {
    if (v !== null) {
      this.brandMatches = this.brands.filter((item) => item.name.toLowerCase().startsWith(v.toLowerCase()))
    } else {
      this.brandMatches = this.brands
    }
  }

  queryCitySelections(v: string) {
    if (v !== null) {
      this.citiesMatches = this.cities.filter((item) => item.name.toLowerCase().startsWith(v.toLowerCase()))
    } else {
      this.citiesMatches = this.cities
    }
  }

  created() {
    if (this.$router.currentRoute.params.categoryId) {
      // @ts-ignore
      this.categoryId = this.$router.currentRoute.params.categoryId
    }
    if (this.$router.currentRoute.params.id) {
      // @ts-ignore
      this.partnerId = this.$router.currentRoute.params.id
    }
    console.log(this.partnerDetails)
    // console.log(this.partnerId)
  }

  deleteBrand(brandId) {
    const index = this.partnerDetails.brandIds.indexOf(brandId)
    if (index !== -1) {
      this.partnerDetails.brandIds.splice(index, 1)
    }
  }

  getDadataAddress() {
    Partners.getDadataAddress(this.partnerDetails.location.address)
      .then(({ data }) => {
        if (data.success) {
          console.log(data)
          this.partnerDetails.location.address = data.address
          this.partnerDetails.location.cityId = data.cityId
          this.partnerDetails.location.regionId = data.regionId
          this.partnerDetails.location.lon = data.lon
          this.partnerDetails.location.lat = data.lat
          this.cities = data.cities
          this.regions = data.regions
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
  }

  get isDealership() {
    return this.partnerDetails.categoryId === PartnerCategory.Dealership
  }

  get isLeasingCompany() {
    return this.partnerDetails.categoryId === PartnerCategory.LeasingCompany
  }

  get nameFieldSize() {
    if (this.isDealership && this.isNotNewPartner) {
      return 3
    } else if ((this.isDealership && !this.isNotNewPartner) || (!this.isDealership && this.isNotNewPartner)) {
      return 6
    } else {
      return 9
    }
  }

  trialLabel() {
    // @ts-ignore
    if (this.partnerDetails.is_trial === '0' || typeof this.partnerDetails.request_left === 'undefined') return 'Триал'
    // @ts-ignore
    return `Триал (осталось запросов: ${this.partnerDetails.request_left} )`
  }
}
