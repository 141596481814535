
import Vue from 'vue'
import Component from 'vue-class-component'
import { TableHeader } from '@/views/typings'

@Component

export default class TableMixin extends Vue {
  isTableList = false

  mounted() {
    this.checkTypeTable()
  }

  checkTypeTable() {
    this.isTableList = window.innerWidth > 770
  }

  findHeader(value: any, headers: TableHeader[]) {
    const header = headers.find((header: TableHeader) => {
      return header.value === value
    })
    return header ? header.text : ''
  }
}
