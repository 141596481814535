export interface UserRole {
  id: number;
  name: string;
}

export interface Networks {
  id: number;
  name: string;
}

export interface City {
  id: number;
  name: string;
}

export interface Region {
  id: number;
  name: string;
}

export interface CarBrand {
  id: number;
  name: string;
}

export interface LeasingCompany {
  id: number;
  name: string;
}

export interface Dealership {
  id: number;
  name: string;
}

export interface Location {
  cityId: number;
  regionId: number;
  address: string;
  lon: number;
  lat: number;
}

export interface Status {
  id: number;
  name: string;
}

export interface UserDetails {
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  roleId: number;
  companyIds: number[] | null | number;
  brandIds: number[];
  password: number;
  statusId: number;
}

export interface PartnerDetails {
  categoryId: number;
  networkId: number;
  name: string;
  statusId?: number;
  brandIds: number[];
  location: Location;
}

export interface BrandDetails {
  id: number;
  name: string;
}

export interface ModelDetails {
  id: number;
  name: string;
  // brandId: number;
}

export enum UserRoleId {
  Admin = 1,
  ManagerDealership = 2,
  ManagerLeasingCompany = 3,
  SupervisorLeasingCompany = 4,
  ApiUser = 5
}

export enum PartnerCategory {
  Dealership = 1,
  LeasingCompany = 2,
  DealershipNetwork = 3
}

export enum UserStatusId {
  Active = 1,
  Locked = 2
}

export enum RezervStatusId {
  REZERV_WAIT = 1,
  REZERV_CONFIRM = 2,
  REZERV_CANCEL = 3,
  REZERV_NOT = 4
}

export enum RezervCancelStatusId {
  NOT_CANCEL = 1,
  CANCEL_OFFER = 2,
  CANCEL_CLIENT = 3,
  ANOTHER_OFFER = 4
}
