import { Http } from '@/apis/Http'
// import Csrf from '@/apis/Csrf'

export default {
  fetchPartners() {
    return Http.get('/admin/get-partners')
  },

  fetchComboboxesForPartner() {
    return Http.get('/admin/fetch-comboboxes-for-partner')
  },

  fetchDetailsForUpdatePartner(partnerId, categoryId) {
    return Http.get(`/admin/fetch-details-for-update-partner/${partnerId}/${categoryId}`)
  },

  async storePartner(params) {
    return Http.post('/admin/partners', params)
  },

  async updatePartner(params, partnerId) {
    return Http.put(`/admin/partners/${partnerId}`, params)
  },

  getDadataAddress(address) {
    return Http.get('/admin/get-dadata-address', {
      params: {
        address: address
      }
    })
  }
}
